<template>
  <div>
    <h1 class='text-2xl mb-16'>
      {{title}}
    </h1>
    <div class='mb-8'>
      <label class='form-label'>Field 명</label>
      <input type='text'
             v-model='customFieldName'
             class='form-input w-full h-12'/>
    </div>
    <div class='mb-8'>
      <label class='form-label'>Data On</label>
      <select class='form-select' v-model='templateType' :disabled='fixed'>
        <option v-for='templateType in templateTypes'
                :value='templateType'
                :key='`templatetype-${templateType}`'>
          {{ templateType }}
        </option>
      </select>
    </div>
    <div class='mb-8 flex flex-row justify-start'>
      <div>
        <label class='form-label'>필드 속성</label>
        <select class='form-select' v-model='fieldType' :disabled='fixed'>
          <option v-for='fieldType in filteredFieldTypes'
                  :value='fieldType'
                  :key='`fieldtype-${fieldType}`'>
            {{ fieldType }}
          </option>
        </select>
      </div>
      <custom-field-template-selectable-values class='ml-8 flex-grow' v-if='isSelectableTemplate'></custom-field-template-selectable-values>
    </div>
    <custom-field-template-table-headers class='mb-8 flex-grow' v-if='isTableTemplate'></custom-field-template-table-headers>
    <div class='mb-8' v-if='recurrable && !fixed'>
      <label class='form-label'>Recurring</label>
      <label for='recurringCheckbox' class='ml-4 mt-3'><input type='checkbox' id='recurringCheckbox' v-model='recurring'> 반복</label>
    </div>
    <div class='mb-8' v-if='requirable'>
      <label class='form-label'>Required</label>
      <label for='requireCheckbox' class='ml-4 mt-3'><input type='checkbox' id='requireCheckbox' v-model='required'> 필수</label>
    </div>
    <div class='mb-4'>
      <label class='form-label'>필드 표시 조건</label>
      <custom-field-template-rules class='mt-4'></custom-field-template-rules>
    </div>
    <div v-if='!showConfirmDelete'
      class='border-t pt-12 flex flex-row'>
      <div class='flex-grow'>
        <button class='border w-2/5 h-12 text-white rounded-md font-bold defaultStyle'
          @click='submitAddOrEdit' :disabled='invalidInput'>
          {{buttonTitle}}
        </button>
        <button class='ml-4 h-12 bg-white text-gray-600'
          @click='cancelEditing'>
          취소
        </button>
      </div>
      <button v-if='isEditingExistingCustomFieldTemplate && !fixed'
        class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md float-right'
        @click='showConfirm'>
        삭제
      </button>
    </div>
    <div v-else class='p-8 text-center mt-4 border rounded-md'>
      <h4 class='mb-8'><span class='font-semibold'>{{editingCustomField.custom_field_name}}</span> 삭제하시겠습니까?</h4>
      <button @click='hideConfirm' class='py-3 px-6 rounded-md text-sm border border-gray-400 mr-4 hover:border-gray-800'>취소</button>
      <button @click='submitDelete' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>삭제</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EventBus from '@/utils/event-bus'
import CustomFieldTemplateRules            from '@/views/custom_fields/CustomFieldTemplateRules.vue'
import CustomFieldTemplateSelectableValues from '@/views/custom_fields/CustomFieldTemplateSelectableValues.vue'
import CustomFieldTemplateTableHeaders     from '@/views/custom_fields/CustomFieldTemplateTableHeaders.vue'

export default {
  name: 'CustomFieldTemplateDetails',
  components: {
    CustomFieldTemplateRules,
    CustomFieldTemplateSelectableValues,
    CustomFieldTemplateTableHeaders,
  },
  data () {
    return {
      showConfirmDelete: false,
      submit: false,
    }
  },
  computed: {
    ...mapState('customFields', [
      'editingCustomField',
      'fieldTypes',
      'templateTypes',
      'recurrableTemplateTypes',
      'requirableTemplateTypes',
    ]),
    ...mapState('dashboardViews', [
      'dashboardViews',
    ]),
    ...mapState('users', [
      'roles',
    ]),
    ...mapGetters('customFields', [
      'isEditingExistingCustomFieldTemplate',
      'isSelectableTemplate',
      'isTableTemplate',
    ]),
    ...mapGetters('companySettings', [
      'isMsStorageMode'
    ]),
    customFieldName: {
      get () {
        return this.editingCustomField.custom_field_name
      },
      set (value) {
        this.updateCustomFieldName(value)
      }
    },
    fieldType: {
      get () {
        return this.editingCustomField.field_type
      },
      set (value) {
        this.updateCustomFieldType(value)
      }
    },
    requirable () {
      return this.requirableTemplateTypes.includes(this.editingCustomField.field_type)
    },
    required: {
      get () {
        return this.editingCustomField.required
      },
      set (value) {
        this.updateCustomFieldRequired(value)
      }
    },
    recurrable () {
      return this.recurrableTemplateTypes.includes(this.editingCustomField.field_type)
    },
    recurring: {
      get () {
        return this.editingCustomField.recurring
      },
      set (value) {
        this.updateCustomFieldRecurring(value)
      }
    },
    rules: {
      get () {
        return this.editingCustomField.rules
      },
      set (value) {
        this.updateCustomFieldRules(value)
      }
    },
    templateType: {
      get () {
        return this.editingCustomField.template_type
      },
      set (value) {
        this.updateCustomFieldTemplateType(value)
      }
    },
    filteredFieldTypes () {
      return this.isMsStorageMode ? this.fieldTypes.filter(t => t !== 'file') : this.fieldTypes
    },
    buttonTitle () {
      return (this.isEditingExistingCustomFieldTemplate) ? '수정' : '추가'
    },
    fixed () {
      return this.editingCustomField.is_created_by_field
    },
    title () {
      return (this.isEditingExistingCustomFieldTemplate) ? this.customFieldName : 'New Field'
    },
    invalidInput () {
      return !this.customFieldName || !this.templateType || !this.fieldType
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('customFields', [
      'createCustomFieldTemplate',
      'editCustomFieldTemplate',
      'getCustomFieldTemplates',
      'deleteCustomFieldTemplate',
    ]),
    ...mapMutations('customFields', [
      'updateCustomFieldName',
      'updateCustomFieldType',
      'updateCustomFieldRequired',
      'updateCustomFieldRecurring',
      'updateCustomFieldRules',
      'updateCustomFieldTemplateType',
      'updateCustomFieldTemplateMetadata',
    ]),
    cancelEditing () {
      this.doneEditing()
    },
    doneEditing () {
      this.sidepanelClose()
    },
    submitAddOrEdit () {
      EventBus.$emit('check-add-rule')
      if (this.isEditingExistingCustomFieldTemplate) {
        this.editCustomFieldTemplate().then(() => {
          this.doneEditing()
        })
      } else {
        this.createCustomFieldTemplate().then(() => {
          this.doneEditing()
        })
      }
    },
    submitDelete () {
      this.deleteCustomFieldTemplate(this.editingCustomField.id).then(() => {
        this.doneEditing()
        this.hideConfirm()
      })
    },
    hideConfirm () {
      this.showConfirmDelete = false
    },
    showConfirm () {
      this.showConfirmDelete = true
    },
    updateMetadataRole (role) {
      this.updateCustomFieldTemplateMetadata(role)
    }
  },
}
</script>

<style lang='scss' scoped>
.defaultStyle {
  background-color: #223645;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}
</style>
